<template>
        <v-container v-if="$store.state.user">

        <v-row class="text-center mt-10">
            <v-col class="mb-2">
                <h1 class="display-2 font-weight-bold mb-3">Add Person</h1>
            </v-col>
        </v-row>

        <v-row class="justify-center">
            <v-col class="mt-16" cols="4">

                <!-- Start of Form & Text Fields -->
                <v-form ref="form" model="valid" lazy-validation>
                    <v-text-field v-model="first_name" :rules="nameRules" label="First Name"></v-text-field>
                    <v-text-field v-model="last_name" :rules="nameRules" label="Last Name" required></v-text-field>
                    <v-text-field v-model="address_one" label="Address One" required></v-text-field>
                    <v-text-field v-model="address_two" label="Address Two"></v-text-field>
                    <v-text-field v-model="city" label="City" required></v-text-field>
                    <v-text-field v-model="state" label="State" required></v-text-field>
                    <v-text-field v-model="zip" label="Zip Code" required></v-text-field>
                    <v-text-field v-model="phone" label="Phone Number" required></v-text-field>
                    <v-text-field v-model="file_number" label="File Number" required></v-text-field>
                    <v-textarea v-model="notes" label="Notes"></v-textarea>

                    <!-- This is a dropdown so the user can change what association to add this person to. -->
                    <v-select v-model="association_id" :items="associations" item-text="name"
                        item-value="id" label="Association" required>
                    </v-select>

                    <!-- Submit Button -->
                    <v-btn :disabled="!valid" color="success" class="mr-4" @click="validate">Submit</v-btn>

                    <!-- Snackbar (activates when user clicks "SUBMIT" button) -->
                    <v-snackbar v-model="snackbar">
                        New person added successfully!

                        <template v-slot:action="{ attrs }">
                            <v-btn color="primary" text v-bind="attrs" @click="snackbar = false">Close</v-btn>
                        </template>
                    </v-snackbar>

                    <!-- Button to clear the entire form (based on method defined in script tag) -->
                    <v-btn color="error" class="mr-4" @click="reset">Clear Form</v-btn>
                </v-form>
            </v-col> 
        </v-row>
        </v-container>
</template>

<script>
    import AssociationsService from '@/services/AssociationsService'
    import PeopleService from '../../services/PeopleService'
    export default {
        name: 'addNewPerson',

        data () {
            return {
                valid: true,
                first_name: '',
                last_name: '',
                address_one: '',
                address_two: '',
                city: '',
                state: '',
                zip: '',
                phone: '',
                file_number: '',
                notes: '',
                association_id: '',
                associations: [],
                snackbar: false,
                nameRules: [
                    v => !!v || 'Name is required'
                ],
            }
        },

        async mounted() {
            AssociationsService.getAllAssociations().then(result => {
                result.data.rows.forEach(element => {
                    this.associations.push({
                        id: element.id,
                        name: element.name
                    })
                })
            })
            this.association_id = parseInt(this.$store.state.route.params.association_id)
        },

        methods: {
            validate () {

                // Check user input against custom rules above
                this.$refs.form.validate()

                // Create payload and hit API in PeopleService
                try {
                    PeopleService.addNewPerson({
                        first_name: this.first_name,
                        last_name: this.last_name,
                        address_one: this.address_one,
                        address_two: this.address_two,
                        city: this.city,
                        state: this.state,
                        zip: this.zip,
                        phone: this.phone,
                        file_number: this.file_number,
                        notes: this.notes,
                        association_id: parseFloat(this.association_id),
                        archived: false
                    })

                    this.$router.go(-1)
                } catch (error) {
                    this.error = error.response.data.error
                }

                // Fire snackbar and reset form
                this.snackbar = true
                this.$refs.form.reset()
            },

            // Function to reset all fields currently populated in the form
            reset () {
                this.$refs.form.reset()
            },
        },
    }
</script>
